// @flow

import React from 'react'
import Layout from '../../../../components/Layout/index'
import Header from '../../../../modules/Header'
import Footer from '../../../../modules/Footer'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../../../components/SubpageHeroTeaser'
import IntroTeaser from '../../../../components/IntroTeaser'
import Services from '../../../../components/Services'
import ServicesNav from '../../../../components/ServicePages/ServicesNav'
import IntegrationExample from '../../../../components/ServicePages/IntegrationExample'

import messages from '../../../../components/ServicePages/ServicePages.lang'
import metaDescription from '../../../../messages/pageMetaDescriptions.lang'
import pageTitle from '../../../../messages/pageTitles.lang'
import baseStyles from '../../../../components/ServicePages/ServicePages.scss'
import SEO from '../../../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const IntroImage = (
  <StaticImage
    src="../../../../images/services/subpages/hcms-integration.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

import MefaImgSrc from '../../../../images/clientlogos/logo-mitsubishi.png'
const MefaImg = <img src={MefaImgSrc} alt="" className="image" />

import CertifiedImgSrc from '../../../../images/logo/contentstack-certified.png'
const CertifiedImg = <img src={CertifiedImgSrc} alt="" className="image certifiedBadge" />

type Props = {
  ...StyleProps
}

const ServicePage = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleContentstack)}
        description={formatMessage(metaDescription.contentstackDescription)}
        lang={locale}
      />
      <ServicesNav />
      <div className={styles.root}>
        <SubpageHeroTeaser title={formatMessage(messages.hcmsIntegrationTitle)} image={HeroTeaserImage} />
        <IntroTeaser
          title={formatMessage(messages.contentstackIntroTitle)}
          copy={
            <div className={styles.text}>
              <IntegrationExample
                description={messages.contentstackDescription}
                integrationTitle={formatMessage(messages.contentstackIntegrationTitle)}
                integrationLogo={CertifiedImg}
                exampleTitle={formatMessage(messages.integrationExampleTitle)}
                exampleLogo={MefaImg}
                exampleText={formatMessage(messages.contentstackExampleText).replaceAll("'", '')}
                exampleTech={<>Contentstack, NextJS, Keycloak, MailJet, {formatMessage(messages.clientSystems)}</>}
                exampleLive={formatMessage(messages.contentstackExampleLive)}
                exampleStart={formatMessage(messages.contentstackExampleStart)}
              />
            </div>
          }
          image={IntroImage}
          fullWidth
        />
      </div>
      <Services
        services={[
          'consulting',
          'conceptStrategy',
          'design',
          'development',
          'systemIntegration',
          'systemEngineering',
          'appDevelopment',
          'hcmsIntegration'
        ]}
      />
    </Layout>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicePage)
